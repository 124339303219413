<template>
  <div class="">
    <PageTitle
      title="獎勵紀錄"
      icon="chevron_left"
      style="margin-bottom: 64px"
      btn="編輯活動"
      btn2="序號管理"
      @btnClick="$router.push({name: 'RewardActivityEdit', params: {id: activityId} })"
      @btn2Click="$router.push({name: 'SerialCodeManagement', params: {id: activityId} })"
      @iconClick="$router.push({ name: 'RewardActivityList' })"
    />

    <SerialActivityInfoBlock
      :activity="activityData"
    />

    <FiltersContainer style="margin-top: 20px">
      <el-input
        v-model="search.code"
        placeholder="請輸入序號搜尋"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
    </FiltersContainer>

    <SerialActivityRewardRecordLogTable v-loading="loading.table" :tableData="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import SerialActivityInfoBlock from '@/views/RewardActivity/components/SerialActivityInfoBlock.vue'
import SerialActivityRewardRecordLogTable from './components/SerialActivityRewardRecordLogTable.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import { useRouter, useRoute } from 'vue-router/composables'
import {
  FindSerialActivity,
  GetSerialActivityLog as GetDataAPI,
  GetSerialActivityLogCount as GetDataCountAPI,
} from '@/api/serialActivity'
import { useTable } from '@/use/table'
import { get } from 'lodash'

export default defineComponent({
  name: 'SerialActivityRewardRecord',
  components: {
    PageTitle,
    SerialActivityInfoBlock,
    FiltersContainer,
    SerialActivityRewardRecordLogTable,
  },
  setup (props) {
    const route = useRoute()
    const activityId = computed(() => route.params.id)
    const activityData = ref({})
    const {
      shopId, loading, search, extendData, pageStartIndex,
      tableData, tableDataCount, tableOptions,
    } = useTable()

    extendData('search', {
      member: null,
      code: null,
      status: null,
    })

    const findSerialActivity = async () => {
      const [res, err] = await FindSerialActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      activityData.value = res
    }

    const getData = async (payload) => {
      const [res, err] = await GetDataAPI(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getDataCount = async (payload) => {
      const [res, err] = await GetDataCountAPI(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        type: search.type || undefined,
        MemberId: get(search, 'member.id') || undefined,
        code: search.code || undefined,
        SerialActivityId: activityId.value,
        status: search.status || undefined,
      }
      await Promise.allSettled([
        getData(payload),
        getDataCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      findSerialActivity()
      refresh()
    })

    return {
      activityId,
      activityData,
      loading,
      search,
      tableData,
      tableDataCount,
      tableOptions,
      refresh,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
