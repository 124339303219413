<template>
  <div class="card-container form-wrapper">
    <p class="card-title">活動資料</p>
    <el-form label-position="left">
      <el-form-item label="活動名稱：">
        {{ displayData.name }}
      </el-form-item>
      <el-form-item label="活動時間：">
        {{ displayData.timeRange }}
      </el-form-item>
      <el-form-item label="活動連結：">
        <div class="flex items-center">
          <span style="margin-right: 8px">{{ displayData.shortLink }}</span>
          <span
            class="material-icons text-primary-100 cursor-pointer"
            style="width: 24px; transform: scaleX(-1)"
            @click="copyLink"
          >
            content_copy_outlined
          </span>
        </div>
      </el-form-item>
      <el-form-item label="活動狀態：">
        <Tag :type="get(displayData.status, 'tagType')">
          {{ get(displayData.status, 'label') }}
        </Tag>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { serialActivityStatusConfig } from '@/config/serialActivity'
import Tag from '@/components/Tag/Tag.vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
import copy from 'clipboard-copy'

export default defineComponent({
  name: 'SerialActivityInfoBlock',
  components: { Tag },
  props: {
    activity: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = props.activity
      const start = get(data, 'startAt') ? formatDate(data.startAt) : null
      const end = get(data, 'endAt') ? formatDate(data.endAt) : null

      return {
        name: get(data, 'name') || '-',
        timeRange: start && end ? `${start} 至 ${end}` : '-',
        status: get(serialActivityStatusConfig, get(data, 'status')),
        shortLink: get(data, 'shortLink') || '-',
      }
    })

    const copyLink = async () => {
      const link = get(displayData.value, 'shortLink')
      if (!link) return
      await copy(link)
      window.$message.success('複製成功！')
    }
    return { get, displayData, copyLink }
  },
})
</script>

<style lang="postcss" scoped>
.form-wrapper {
  @apply px-[24px] py-[20px];
}
</style>
