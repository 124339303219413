<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="會員名稱" prop="name" align="center" />
      <el-table-column label="序號" prop="code" align="center" />
      <el-table-column label="狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'status.tagType')">{{ get(scope.row, 'status.label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="登錄時間" prop="createdAt" align="center" width="120px" />
      <el-table-column label="備註" prop="comment" align="center" />
    </el-table>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'
import { serialActivityLogStatusConfig } from '@/config/serialActivity'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'

export default defineComponent({
  name: 'SerialActivityRewardRecordLogTable',
  components: { EmptyBlock, Tag },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { dateFormat } = useTable()

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, (i) => {
        const rewardType = get(i, 'RewardRecord.rewardType')
        const rewardAmount = get(i, 'RewardRecord.rewardAmount')
        const rewardTypeData = get(rewardActivityTypeConfig, rewardType)
        const errorMsg = i.errorMsg

        return {
          id: i.id,
          name: get(i, 'Member.UserInfo.name'),
          code: i.code,
          status: get(serialActivityLogStatusConfig, i.type),
          createdAt: dateFormat(i.createdAt),
          comment: errorMsg ? `失敗原因：${errorMsg}` : `預計獲得 ${rewardAmount} ${get(rewardTypeData, 'unit')}${get(rewardTypeData, 'label')}`,
        }
      })
    })

    return {
      get,
      displayData,
    }
  },
})
</script>
